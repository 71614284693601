import styles from './css/Success.module.css';
import cocaTeam from '../../assets/coca-team-with-drops.png';
import whatsAppImg from '../../assets/whatsapp-icon.png';
import cocaLogo from '../../assets/coca-logo-white.png';
import taqeLogo from '../../assets/taqe-logo-green.png';
import { useEffect } from 'react';

const Success = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.success_page}>
      <div className={styles.success_message}>
        <div className={styles.background} />
        <h1>Inscrição feita<br />com sucesso!</h1>
        <div className={styles.team_coca_image}>
          <img src={cocaTeam} alt="Team Coca" />
        </div>
      </div>
      <div className={styles.message_whatsapp_container}>
        <div className={styles.whatsapp_text_box}>
          <div className={styles.whatsapp_img}>
            <img src={whatsAppImg} alt="" />
          </div>
          <div className={styles.whatsapp_text}>
            <p>Em breve entraremos em<br />contato através do WhatsApp,<br /><span>fique ligado! E bom estudo.</span></p>
          </div>
          <div className={styles.logosContainer}>
            <div className={styles.cocaLogo}>
              <img src={cocaLogo} alt="Logo do Instituto Coca-Cola Brasil" />
            </div>
            <div className={styles.taqeLogo}>
              <img src={taqeLogo} alt="Logo da Taqe" />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Success;
