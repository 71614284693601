import React, { useEffect } from 'react';
import styles from './css/Rejection.module.css';
import cocaTeam from '../../assets/coca-team-with-drops.png';
import cocaLogo from '../../assets/coca-logo-white.png';
import taqeLogo from '../../assets/taqe-logo-green.png';
import { useForm } from '../../Utils/FormContext';

const Rejection = () => {
  const [message, setMessage] = React.useState<string>('Agradecemos pelo interesse, mas sua inscrição não foi realizada.');
  const { jsonResponse } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!jsonResponse) {
      return;
    }

    switch (String(jsonResponse?.details).trim()) {
      case 'Jovem já está cadastrado.':
        setMessage(
          'Parece que você já está inscrito no Coletivo Online, pois encontramos o seu CPF em nossos sistemas.'
        );
        break;
      default:
        setMessage(
          'Agradecemos pelo interesse, mas sua inscrição não foi realizada.'
        );
        break;
    }
  }, [jsonResponse]);

  return (
    <div className={styles.rejection_page}>
      <div className={styles.rejection_message}>
        <div className={styles.background} />
        <h1 className={styles.title}>{message}</h1>
        <div className={styles.team_coca_image}>
          <img src={cocaTeam} alt="Team Coca" />
        </div>
      </div>
      <div className={styles.message_whatsapp_container}>
        <div className={styles.whatsapp_text_box}>
          <div className={styles.whatsapp_text}>
            <span>
              Nesse momento você não possui o perfil para participar do curso,
              mas não desanime: você pode procurar milhares de vagas dentro da{' '}
              <a
                href="https://www.vagas.taqe.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Taqe
              </a>
              .
            </span>
          </div>
          <div className={styles.logosContainer}>
            <div className={styles.cocaLogo}>
              <img src={cocaLogo} alt="Logo do Instituto Coca-Cola Brasil" />
            </div>
            <div className={styles.taqeLogo}>
              <img src={taqeLogo} alt="Logo da Taqe" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rejection;
