import { useState } from 'react';
import './App.css';
import CocaInfos from './Components/CocaInfos';
import CookiesPopup from './Components/CookiesPopup';
import Form from './Components/Form';
import Rejection from './Components/Rejection';
import Success from './Components/Success';
import Support from './Components/Support';
import { useForm } from './Utils/FormContext';

const App = () => {
  const { answered, isRefused } = useForm();
  const [cookies, setCookies] = useState(localStorage.getItem('cookies'));

  if (isRefused) {
    return <Rejection />;
  }

  return (
    <>
      {answered ? (
        <Success />
      ) : (
        <div className={`App ${cookies ? '' : 'no-cookies'}`}>
          <CocaInfos />
          <Form />
          <Support />
          {!cookies && <CookiesPopup setCookies={setCookies} />}
        </div>
      )}
    </>
  );
};

export default App;
