// based on: https://dev.to/franciscomendes10866/how-to-create-a-modal-in-react-3coc

import React from 'react';
import styles from './css/Modal.module.css';
import { RiCloseLine } from 'react-icons/ri';

type ModalProps = {
  setIsOpen: (isOpen: boolean) => void;
};

const Modal: React.FC<ModalProps> = ({ setIsOpen }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>Termos de uso e imagem</h5>
            <button
              className={styles.closeBtn}
              onClick={() => setIsOpen(false)}
            >
              <RiCloseLine style={{ marginBottom: '-3px' }} />
            </button>
          </div>
          <div className={styles.modalContent}>
            <p>
              Olá, seguem algumas informações importantes sobre o Curso, que
              você precisa ler e aceitar antes de continuar.
            </p>
            <p>
              Se você é menor de idade, favor informar os seguintes dados
              pessoais do seu responsável legal:
            </p>
            <p>
              Nome completo, telefone de contato e CPF (
              <em>Dados do Responsável Legal</em>)
            </p>
            <p>
              Ao aceitar todas as informações e condições abaixo, você confirma
              que o seu responsável legal está ciente e de acordo com o uso dos
              seus dados pessoais para as finalidades específicas descritas no
              presente documento.
            </p>
            <p>
              Para aceitar, é necessário declarar que leu e entendeu os termos
              do Curso e concorda em participar e em compartilhar os dados
              pessoais, da forma e para as finalidades informadas.
            </p>
            <ul>
              <li>
                O "Curso" é fornecido de maneira gratuita e para jovens de 16 a
                29 anos, que possuam acesso à plataforma, tenham recebido o link
                de convite e estão habilitados para acessar o "Curso". Pode
                haver restrições, verifique.
              </li>
              <li>
                O "Curso" é realizado pelo Instituto Coca-Cola Brasil, que
                coleta e trata dados pessoais dos participantes na figura de
                agente Controlador (responsável por tomar as decisões referentes
                ao tratamento desses dados), nos termos da legislação vigente e
                da Lei Geral de Proteção de Dados Pessoais – LGPD (Lei
                13.709/2018).
              </li>
              <li>
                O "Curso" acontecerá pela plataforma e, por meio dela, os
                participantes terão acesso aos vídeos e atividades.
              </li>
              <li>
                No ato da realização da inscrição no “Curso”, serão coletados os
                seguintes dados pessoais dos participantes: nome completo, CPF,
                data de nascimento, número de telefone, e-mail, gênero,
                identidade étnico-racial, deficiência, endereço com
                geolocalização (<em>Dados Cadastrais</em>). Além disso, durante
                a inscrição, o participante poderá, a seu exclusivo critério,
                compartilhar os seguintes dados com o Instituto Coca-Cola
                Brasil: nome social, pronomes (<em>Dados Opcionais</em>). O
                Instituto Coca-Cola Brasil terá acesso aos dados cadastrais dos
                participantes para realizar a validação da inscrição no “Curso”
                e realizar a liberação do acesso ao conteúdo e terá acesso aos
                dados opcionais dos participantes para melhorar a experiência
                dos participantes na plataforma.
              </li>
              <li>
                Durante a realização do "Curso", serão oferecidas atividades,
                nas quais poderão ser coletados os seguintes dados pessoais dos
                participantes: renda pessoal, renda familiar e quantidade de
                residentes (<em>Dados de Renda</em>), bem como formação,
                experiência profissional, idioma, competência e habilidades (
                <em>Dados Profissionais</em>). O Instituto Coca-Cola Brasil terá
                acesso aos dados de renda e profissionais dos participantes,
                inclusive às respostas das atividades, para conexão com
                oportunidades de geração de renda, verificação de aproveitamento
                e estatísticas.
              </li>
              <li>
                Os participantes concordam em receber uma mensagem de
                boas-vindas com o link da plataforma por WhatsApp/E-mail. E,
                posteriormente, caso queiram, poderão suspender o envio de
                mensagens na própria plataforma.
              </li>
              <li>
                Os dados pessoais dos participantes serão protegidos por medidas
                de segurança, técnicas e administrativas e o tratamento desses
                dados segue as normas da Lei Geral de Proteção de Dados Pessoais
                – LGPD.
              </li>
              <li>
                Os dados pessoais dos participantes serão utilizados pelo
                Instituto Coca-Cola Brasil para finalidades diretamente
                relacionadas ao "Curso", divulgação de outras iniciativas de
                capacitação para o mercado de trabalho e/ou conexão com
                oportunidades de geração de renda: recebimento e envio de
                conteúdo, mensagens e atividades, contato com profissionais
                envolvidos em avaliações e estatísticas de aproveitamento para
                geração de relatórios. Os dados pessoais dos jovens também
                poderão ser utilizados pelo Instituto Coca-Cola Brasil para a
                realização de contato para pesquisas de satisfação e resultado,
                participação em eventos e futuros projetos. Além disso, poderá
                haver o uso de depoimentos dos participantes para demonstração
                de potenciais oportunidades de geração de renda.
              </li>
            </ul>
            <p>
              Os dados cadastrais dos participantes poderão ser compartilhados
              com parceiros empregadores, fornecedores e clientes para a
              finalidade de divulgação de outras capacitações, realização de
              pesquisas e/ou conexão com oportunidades de geração de renda.
              Desta forma, o participante autoriza o compartilhamento e
              declara-se ciente de que caberá ao Parceiro Empregador integral e
              exclusiva responsabilidade por toda e qualquer operação de
              tratamento dos dados compartilhados, cuja utilização deverá ser
              realizada para os fins previamente consentidos pelos
              participantes, nos termos da legislação vigente e da Lei Geral de
              Proteção de Dados Pessoais – LGPD.
            </p>
            <ul>
              <li>
                Os dados pessoais dos participantes serão mantidos pelo
                Instituto Coca-Cola Brasil até que seja verificado que a
                finalidade foi alcançada ou que deixem de ser
                necessários/pertinentes ao alcance da finalidade específica
                almejada ou que ocorra a revogação do consentimento, conforme
                preceitua a lei.
              </li>
              <li>
                Os participantes do "Curso", como titulares de dados, têm os
                direitos previstos pelo artigo 18 da Lei Geral de Proteção de
                Dados Pessoais – LGPD, inclusive de revogação do consentimento.
                Pedidos e comunicações a respeito desses direitos e de proteção
                de dados deverão ser enviados para o atendimento na plataforma
                ou para o e-mail{' '}
                <a href="mailto:lgpd@institutococacolabrasil.org">
                  lgpd@institutococacolabrasil.org
                </a>
                .
              </li>
              <li>
                Em caso de dúvidas sobre temas relacionados à Privacidade de
                Dados, entre em contato conosco através do e-mail{' '}
                <a href="mailto:encarregado.lgpd@institutococacolabrasil.org">
                  encarregado.lgpd@institutococacolabrasil.org
                </a>{' '}
                ou envie uma correspondência para o endereço Praia de Botafogo,
                nº 374, Botafogo, Rio de Janeiro/RJ, CEP 22.250-040, A/C Flávia
                Dias França.
              </li>
              <li>
                O "Curso" tem o direito de excluir os participantes que tenham
                comportamento inadequado.
              </li>
              <li>
                Os participantes somente receberão o certificado tendo concluído
                100% das atividades.
              </li>
              <li>
                Os participantes não têm o direito de divulgar, compartilhar ou
                publicar o conteúdo do "Curso" em nenhum tipo de mídia, sob pena
                de violação de direitos autorais e de imagem.
              </li>
              <li>
                Participar do "Curso" não garante a obtenção de emprego ou
                renda.
              </li>
            </ul>
            <p>
              O "Curso" e todo seu conteúdo são de propriedade exclusiva do
              Instituto Coca-Cola Brasil, CNPJ/ME nº 03.093.215/0001-92. Todos
              os direitos reservados. Para qualquer comentário, sugestão ou
              reclamação envie uma mensagem pela plataforma.
            </p>
            <p>
              <strong>TERMO DE AUTORIZAÇÃO PARA USO DE IMAGEM</strong>
            </p>
            <p>
              Declaro que li, entendi e aceito os termos da autorização de uso
              de imagem.
            </p>
            <p>
              O participante autoriza expressamente o uso de sua imagem pelo
              Instituto Coca-Cola Brasil, consistente em vídeo(s) enviado(s) em
              atividade do "Curso", voz, depoimento, nome, apelido e demais
              atributos da personalidade, sem limite de utilizações ou
              território, para as finalidades a que se propõe. A presente
              autorização é de caráter gratuito e será válida por 5 anos, a
              contar da data de aceite ao presente instrumento, sendo renovável
              gratuitamente, por períodos iguais e consecutivos, caso o
              participante não se manifeste contrariamente com, no mínimo, 60
              dias de antecedência da respectiva data de renovação. A utilização
              da imagem não prejudicará em nenhuma hipótese a honra e identidade
              pessoal do participante, tampouco será feita em contrariedade à
              lei.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
