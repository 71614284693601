import styles from './styles.module.css';

type Props = {
  setCookies: (value: any) => void;
};

const CookiesPopup: React.FC<Props> = ({ setCookies }) => {
  return (
    <div className={styles.cookiesPopup}>
      <p className={styles.cookiesPopupText}>
        Este site armazena cookies em seu computador. Esses cookies são usados
        para melhorar a sua experiência no site e fornecer serviços mais
        personalizados para você, tanto neste site como por meio de outras
        mídias. Para saber mais sobre os cookies que usamos, consulte nosso
        Aviso de Privacidade.
      </p>
      <div className={styles.cookiesPopupButtons}>
        <button
          className={styles.cookiesPopupButton}
          onClick={() => {
            setCookies(true);
            localStorage.setItem('cookies', 'true');
          }}
        >
          Aceitar
        </button>
        <button
          className={styles.cookiesPopupButton}
          onClick={() =>
            window.open(
              'https://www.taqe.com.br/aviso-de-privacidade/',
              '_blank',
            )
          }
        >
          Aviso de Privacidade
        </button>
      </div>
    </div>
  );
};

export default CookiesPopup;
